import { $EventBus } from '@/main';

const hasItems = (arr) => {
	return Array.isArray(arr) && arr?.length;
};

const showError = (errorResponse) => {
	const status = errorResponse?.status || null;
	const data = errorResponse?.data || null;
	let errorMessage =
		'Lo sentimos, ocurrió un error. Por favor, inténtalo nuevamente más tarde.';

	if (status === 422) {
		errorMessage = data?.message;
	} else if (status === 400 || status === 403) {
		errorMessage = Array.isArray(data?.message)
			? data?.message.join('<br> ')
			: data?.message;
	} else if (status === 404) {
		errorMessage = data?.message;
	}
	$EventBus.$emit('showSnack', 'error', errorMessage);
};

const showSnackBar = (message, color = 'info') => {
	if (message) {
		$EventBus.$emit('showSnack', color, message, { text: true });
	}
};

export { hasItems, showError, showSnackBar };
