import { mapActions } from "vuex";

export default {
  name: "DeleteClassificationDialog",
  data() {
    return {
      loadingSave: false,
      listError: []
    }
  },
  props: {
    value: Boolean,
    item: Object,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions("classification", ["deleteClassification"]),
    close() {
      this.dialog = false;
      this.listError = [];
    },
    async deleteItemConfirm() {
      if (this.item) {
        this.loadingSave = true;
        const { ok, error } = await this.deleteClassification(this.item.id);

        if (ok) {
          this.close();
          this.$emit('closeDeleteDialog');
        } else {
          this.listError = Array.isArray(error?.data?.message)
            ? error?.data?.message
            : [error?.data?.message] || [];
          this.loadingSave = false;
        }
        this.loadingSave = false;
      }
    },
  },
};