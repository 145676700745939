import { mapActions, mapState } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";

export default {
  name: "UpdateClassificationDialog",
  data() {
    return {
      listError: [],
      valid: false,
      loadingSave: false,
      classificationForm: {
        name: "",
        area: null,
        responsible_id: null,
        type: null,
      },
      nameRules: [
        (v) => !!v || "Nombre Clasificación es requerido",
        (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ],
      areaRules: [(v) => !!v || "Área es requerida"],
      responsablesRules: [
        (v) => !!v || "Responsable es requerido",
      ],
      type: [
        { name: 'SUBTIPO' },
        { name: 'SERVICIO' },
      ],
      typeRules: [
        (v) => !!v || "Tipo es requerido",
      ],
    };
  },
  props: {
    value: Boolean,
    item: Object,
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.classificationForm = {
          name: this.item.name,
          area: this.item.area.id,
          responsible_id: this.item?.responsible_id?.id || null,
          type: this.item.type,
        };
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState('security', ['usersActive']),
    ...mapState("area", ["allAreas"]),
    sortedAllAreas() {
      return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions("classification", [
      "updateClassification",
      "listClassification",
    ]),
    ...mapActions("area", ["listAllAreas"]),
    close() {
      this.dialog = false;
      this.classificationForm = { name: "", area: null, responsible_id: null, type: null };
      this.listError = [];
      this.$refs?.form?.resetValidation();
    },
    async save() {
      if (!this.$refs?.form?.validate()) {
        return;
      }

      this.listError = [];
      const data = {
        ...this.classificationForm,
        company_id: parseInt(localStorage.getItem("company_id")),
      };
      this.loadingSave = true;
      const { ok, error } = await this.updateClassification({
        id: this.item.id,
        data: data,
      });
      if (ok) {
        this.dialog = false;
        this.$emit("getClassifications");
        this.classificationForm = { name: "", area: "" };
        this.$refs.form.resetValidation();
      } else {
        if (error === "Esta clasificación ya existe para la compañia seleccionada") {
          this.listError.push(
            "Ya existe una clasificación con ese nombre para la misma compañía."
          );

        } else {
          this.listError = Array.isArray(error?.data?.message) ? error?.data?.message : [error?.data?.message] || [];
        }
      }
      this.loadingSave = false;
    },
    dialogInput(value) {
      this.dialog = value;
      if (!value) {
        this.$nextTick(() => {
          this.areaForm = { name: "", company_id: null };
          this.listError = [];
          this.$refs.form.resetValidation();
        });
      }
    },
    customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
  },
  components: {
    ValidationAlert,
  },
};
