<template>
	<classifications-permissions />
</template>
<script>
import ClassificationsPermissions from '@/components/permissions/classifications/index.vue';

export default {
	name: 'HomePermissionsPage',
	components: { ClassificationsPermissions },
};
</script>
