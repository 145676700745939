import { $EventBus } from '@/main';

const printAlert = (color = '', msm = '') => {
	$EventBus.$emit('showSnack', color, msm);
};

const isNotEmpty = (items = [], min = 0) => {
	if (Array.isArray(items) && items.length > min) {
		return true;
	} else {
		printAlert('warning', 'No hay información');
		return false;
	}
};

const hasExtension = (file = File, extensions = ['.xlsx', '.zip']) => {
	let fileName = file?.name;
	if (
		fileName &&
		Array.isArray(extensions) &&
		new RegExp('(' + extensions.join('|').replace(/\./g, '\\.') + ')$').test(fileName)
	) {
		return true;
	} else {
		printAlert('warning', `Archivos válidos (${extensions.join(' ')})`);
		return false;
	}
};

const hasTitles = (items = [], titles = []) => {
	const arr1 = JSON.stringify(items[0]);
	const arr2 = JSON.stringify(titles);
	if (Array.isArray(items) && Array.isArray(titles) && arr1 == arr2) {
		return true;
	} else {
		printAlert('warning', 'Plantilla de excel inválida');
		return false;
	}
};
const validSize = (file = File, maxSize) => {
	if (file.size > maxSize) {
		this.showSnack('warning', 'Seleccione un archivo no mayor a 50MB');
		return false;
	}
	return true;
};

const validateFileSize = (files, maxSize) => {
	if (Array.isArray(files)) {
		let isValid = true;
		const maxSizeBytes = maxSize * 1024 * 1024;
		files.forEach((file) => {
			if (file?.size > maxSizeBytes) {
				isValid = false;
			}
		});
		return isValid;
	}
	return false;
};

const validExtension = (files, allowedExtensions) => {
	let isValid = true;
	if (Array.isArray(files)) {
		files.forEach((element) => {
			const nombreArchivo = element?.name || '';
			const extensionArchivo = nombreArchivo.slice(
				((nombreArchivo.lastIndexOf('.') - 1) >>> 0) + 2
			);
			if (!allowedExtensions.includes('.' + extensionArchivo.toLowerCase())) {
				isValid = false;
			}
		});
	}
	return isValid;
};

const checkIntegerPart = (value) => {
	if (!value) {
		return true;
	}
	const stringValue = value.toString();
	const integerPart = stringValue.split('.')[0];
	return integerPart.length <= 6;
};

const requiredRule = (value) => {
	return !!value || 'Este campo es obligatorio';
};

const decimalRule = (value) => {
	if (value) {
		const regex = /^[0-9]+(\.[0-9]+)?$/;
		return regex.test(value) || 'Ingrese un número válido';
	}
	return true;
};

const integerRule = (value) => {
	const regex = /^\d+$/;
	return regex.test(value) || 'Ingrese un número entero válido';
};

const maxValueRule = (max, value) => {
	if ((value || '')?.length > max) {
		return `Este campo debe tener menos de ${max} carácteres`;
	}
	return true;
};

export {
	isNotEmpty,
	hasExtension,
	hasTitles,
	validSize,
	validateFileSize,
	validExtension,
	checkIntegerPart,
	requiredRule,
	decimalRule,
	integerRule,
	maxValueRule,
};
