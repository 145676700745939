import { mapActions, mapState } from "vuex";
import ValidationAlert from "@/components/global/ValidationAlert.vue";

export default {
  name: "CreateClassificationDialog",
  data() {
    return {
      dialog: false,
      listError: [],
      valid: false,
      loadingSave: false,
      classificationForm: {
        name: "",
        area: null,
        company_id: null,
        type: null
      },
      nameRules: [
        (v) => !!v || "Nombre de Clasificación es requerido",
        (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ],
      areaRules:[
        (v) => !!v || "Área es requerida",
      ],
      responsablesRules:[
        (v) => !!v || "Responsable es requerido",
      ],
      type: [
        { name: 'SUBTIPO' },
        { name: 'SERVICIO' },
      ],
      typeRules:[
        (v) => !!v || "Tipo es requerido",
      ],
    };
  },
  computed: {
    ...mapState({
      companies: (state) => state.auth.user?.companies,
    }),
    // ...mapState({
    //   responsibles: (state) => state.security.usersActive,
    // }),
    ...mapState('security', ['usersActive']),
    ...mapState("area", ["allAreas"]),
    sortedAllAreas() {
      return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
    },
    hasCreatePermission() {
			return this.$store.getters['auth/hasActivity'](
				'permisosAplicacion',
				'listaClasificacion',
				'registrarClasificacion'
			);
		},
  },
  created() {
   // this.getUsersActive();
  },
  methods: {
    ...mapActions("classification", [
      "createClassification",
      "listClassification",
    ]),
   // ...mapActions("security", ["getUsersActive"]),
    async saveClassification() {
      if (!this.$refs.form.validate()) {
        return; 
      }
      this.listError = [];
      const data = {
        ...this.classificationForm,
        company_id: parseInt(localStorage.getItem("company_id")),
      };
      this.loadingSave = true;
      const { ok, error } = await this.createClassification(data);
      if (ok) {
        this.dialog = false;
        await this.$emit('getClassifications');
        await this.$emit('closeCreateDialog');
        this.classificationForm = { name: "", area: "", company_id: '' };
        this.$refs?.form?.resetValidation();
      } else {
        if (error === "Esta área ya existe para la empresa seleccionada") {
          this.listError.push(
            "Ya existe un clasificación con ese nombre para la misma compañía."
          );
        } else {
          this.listError = Array.isArray(error?.data?.message)
            ? error?.data?.message
            : [error?.data?.message] || [];
        }
      }
      this.loadingSave = false;
    },
    dialogInput(value) {
      this.$nextTick(() => {
				this.$refs.button.$el.blur();
				this.$refs.form.resetValidation();
				this.dialog = value;
				if (!value) {
          this.classificationForm = {
                  name: "",
                  area: null,
                  responsible_id: null,
                  type: null
                };
					this.listError = [];
				}
			})
    },
    close() {
      this.dialog = false;
      this.classificationForm = { name: "", area: null, company_id: null, type: null };
      this.listError = [];
      this.$refs?.form?.resetValidation();
    },
    customFilter(item,queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every(term => fullName.includes(term));
		},
  },
  components: {
    ValidationAlert,
  },
};
